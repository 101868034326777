<template>
  <b-container fluid="md" class="px-0">
    <h1>Login</h1>
    <b-form @submit.prevent="handleSubmit" class="mt-5">
      <div>
        <b-form-group class="d-inline">
          <label for="username">Username</label>
          <b-form-input
            type="text"
            v-model="user.username"
            class="text-center"
            required
          />
        </b-form-group>
      </div>
      <div>
        <b-form-group class="d-inline">
          <label htmlFor="password">Password</label>
          <b-form-input
            type="password"
            v-model="user.password"
            class="text-center"
            required
          />
        </b-form-group>
      </div>
      <hr />
      <b-form-group>
        <button class="btn btn-primary btn-primary2" :disabled="loading">
          <b-spinner small v-if="loading" /> Login
        </button>
      </b-form-group>
    </b-form>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },

  methods: {
    ...mapActions(["login", "logout"]),

    async handleSubmit() {
      try {
        this.loading = true;

        await this.login(this.user);
      } catch (error) {
        this.toast(error);
      } finally {
        this.loading = false;
      }
    },

    toast(message) {
      this.$bvToast.toast(message);
    },
  },

  created() {
    this.logout();
  },

  mounted() {
    this.$emit("loading", false);
  },
};
</script>
